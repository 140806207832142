.transaction_history thead tr th {
    background-color: #6AAACF;
    font-size: 12px;
    font-weight: 500 !important;
    color: #fff;
}
.transaction_history tbody tr td {
    font-size: 12px;
    font-weight: 400 !important;
    vertical-align: middle;
}
.transaction_history .label_1 {
  background-color: #A6CEBB;
  color: #fff;
  padding: 7px 25px;
}
.transaction_history  .btn_1{
    background-color: #0D6EFD;
    color: #fff;
    border: none;
    padding: 7px 25px;
}

.tx-link{
    text-decoration: none;
    color: black;
}
.center-content{
    justify-content: space-evenly !important;
}

.input-design{
    border-radius: 8px;
    width: 45%;
    height: 35px;
}
.rdt_Table{
    border: 1px solid #e0e0e0 !important;
    margin: 20px 0px 0px 0px!important;
    box-shadow: 1px 1px 12px gray;
}
.custom-part-files-1{
    border: 1px solid #BC9018;
    padding:10px 20px;
    /* background: var(--Linear, linear-gradient(180deg, #EAC368 0%, #D2B14A 100%)); */
    border-radius: 10px;
    color:#BC9018;
}

.word-wrap-cell {
    word-wrap: break-word;
    max-width: 150px; /* Adjust as needed */
  }

